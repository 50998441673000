<template>
  <b-card>
    <h2>Posez votre question</h2>
    <b-form @submit.prevent="createTransportExpertise">
      <b-row class="mt-2">
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Quelle est votre préoccupation? *"
                label-for="question"
              >
                <validation-provider
                  #default="{ errors }"
                  name="question"
                  rules="required"
                >
                  <b-form-input
                    id="question"
                    v-model="transportExpertise.question"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Comment je fais pour..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="A quelle catégorie appartient votre demande? *"
                label-for="type_expertise_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type_expertise_id"
                  rules="required"
                >
                  <v-select
                    id="type_expertise_id"
                    v-model="transportExpertise.type_expertise_id"
                    label="libelle"
                    size="sm"
                    :options="typeExpertises"
                    :reduce="(typeExpertises) => typeExpertises.id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type d'expertise"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="De quels type d'expert avez-vous besoin? *"
                label-for="type_expertise_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type_expertise_id"
                  rules="required"
                >
                  <v-select
                    id="type_expert_id"
                    v-model="transportExpertise.type_expert_id"
                    label="libelle"
                    size="sm"
                    :options="typeExperts"
                    :reduce="(typeExperts) => typeExperts.id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type d'expert"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Détaillez un peu plus votre préoccupation pour une réponse plus claire *"
                label-for="description"
              >

                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="transportExpertise.description"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Souhaitez vous obtenir plusieurs avis experts? *"
                label-for="si_avis_multiples"
              >
                <validation-provider
                  #default="{ errors }"
                  name="si_avis_multiples"
                  rules="required"
                >
                  <v-select
                    id="si_avis_multiples"
                    v-model="transportExpertise.si_avis_multiples"
                    label="title"
                    size="sm"
                    :options="avisMultiples"
                    :reduce="(avisMultiples) => avisMultiples.value"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Avis multiples"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>

        <b-col md="6">

          <b-row class="justify-content-md-center">

            <b-col
              md="12"
              class=""
            >
              <!-- modal data -->
              <div class="mt-2">

                <div v-if="submittedNames.length === 0">
                  <b-spinner
                    v-if="loader"
                    style="width: 2rem; height: 2rem;"
                    variant="primary"
                    label="Large Spinner"
                    type="grow"
                    class="mt-2"
                  />
                  <b-table
                    v-else
                    :fields="fields"
                    small
                    :empty-filtered-text="'Aucun résultat n\'a été trouvé'"
                    :empty-text="'Aucun résultat n\'a été trouvé'"
                    show-empty
                    responsive="sm"
                    :items="items"
                  >
                    <template #cell(actions)="data">

                      <feather-icon
                        v-b-modal.modal-prevent-image-closing
                        icon="EyeIcon"
                        @click="getImage(data)"
                      />

                      <feather-icon
                        class="text-danger ml-2  w"
                        icon="TrashIcon"
                        @click="removeItem(data)"
                      />
                    </template>
                  </b-table>

                </div>
                <b-list-group v-else>
                  <b-list-group-item
                    v-for="(data, index) in submittedNames"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>

              </div>
            </b-col>

            <b-col
              class="text-center"
              md="12"
            >
              <!-- button -->
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-prevent-closing
                variant="outline-primary"
                size="sm"
              >
                Ajouter une pièce pour appuyer votre question.
              </b-button>

              <div>

                <b-modal
                  id="modal-prevent-image-closing"
                  ref="my-modal"
                  button-size="sm"
                  size="lg"
                  title="Pièce jointe"
                  ok-only
                  ok-title="Fermer"
                >
                  <b-card
                    img-top
                    class="position-static"
                  >
                    <iframe
                      :src="imageUrl"
                      style="width: 100%; height: 600px; border: none;"
                    >
                      Oops! an error has occurred.
                    </iframe>
                  </b-card>

                </b-modal>

                <!-- modal -->
                <b-modal
                  id="modal-prevent-closing"
                  ref="my-modal"
                  no-close-on-backdrop
                  button-size="sm"
                  title="Ajouter une pièce jointe"
                  :busy="documentProcess"
                  ok-title="Ajouter"
                  cancel-title="Fermer"
                  cancel-variant="secondary"
                  ok-variant="success"
                  @show="clearPiece"
                  @ok="handleOk"
                >
                  <validation-observer ref="accountFilesRules">
                    <form @submit.prevent="handleSubmitModal">

                      <b-form-group
                        label="Nom du document"
                        label-for="name_document"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="document"
                          rules="required"
                        >
                          <b-form-input
                            id="name_document"
                            v-model="piece.name_document"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom du document"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Pièce justificative"
                        label-for="fichier"
                        label-size="sm"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="fichier"
                          rules="required"
                        >
                          <b-form-file
                            id="fichier"
                            ref="file-input"
                            v-model="piece.fichier"
                            accept=".pdf"
                            size="sm"
                            placeholder="Choisir un fichier"
                            @change="handleImageSelected"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </form>
                  </validation-observer>

                </b-modal>
              </div>

            </b-col>

          </b-row>

        </b-col>

        <!-- submit and reset -->
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
            :disabled="offreProcess"
          >
            Enregistrer
            <b-spinner
              v-if="offreProcess"
              small
              label="Loading..."
              variant="light"
              class="ml-1"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import _ from 'lodash'
import {
  BCard, BRow, BCol, BFormGroup, BSpinner, BFormInput, BForm, BButton, BTable, VBModal, BFormFile, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useExpertises from '@/services/expertise/expertiseservice'
// eslint-disable-next-line import/no-cycle
import useTypeExpertises from '@/services/expertise/typeExpertiseService'
// eslint-disable-next-line import/no-cycle
import useTypeExperts from '@/services/expertise/typeExpertService'
// eslint-disable-next-line import/no-cycle
import useDocuments from '@/services/admin/documentService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BTable,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const {
      handleCreateTransporteurExpertise, offreProcess,
    } = useExpertises()
    const {
      getTypeExpertises, typeExpertises,
    } = useTypeExpertises()
    const {
      getTypeExperts, typeExperts,
    } = useTypeExperts()
    const {
      documents, documentProcess,
    } = useDocuments()
    const {
      imageUrl, imageFile, loader, handleImageSelected, fileName, uploadExpertiseFile, fil, deleteFile,
    } = useImageUpload()

    onMounted(async () => {
      await getTypeExpertises()
      await getTypeExperts()
      // Set the initial number of items
    })

    const avisMultiples = [
      { value: true, title: 'Oui' },
      { value: false, title: 'Non' },
    ]
    const items = reactive([])

    const piece = ref({})
    const clearPiece = () => {
      piece.value = {}
      imageUrl.value = ''
      fileName.value = ''
    }
    async function queuePurchase() {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadExpertiseFile(data)

      piece.value.document = piece.value.name_document
      piece.value.fileName = fil.value
      piece.value.imageUrl = imageUrl.value
      items.push(piece.value)
      clearPiece()
    }

    const getImage = data => {
      console.clear()
      imageUrl.value = data.item.imageUrl
    }

    const removeItem = async data => {
      console.clear()
      await deleteFile(data.item.fileName)
      const indexOfObject = items.findIndex(object => object.fileName === data.item.fileName)
      items.splice(indexOfObject, 1)
    }
    const transportExpertise = reactive({
      type_expertise_id: '',
      question: '',
      description: '',
      si_avis_multiples: '',
      date_traitee: '',
      date_encours: '',
      reference: '',
      statut: true,
      user_id: items,
      fichiers: items,
    })

    const submittedNames = []
    const fields = reactive([
      { key: 'document', label: 'Nom document' },
      { key: 'actions' },
    ])

    const createTransportExpertise = async () => {
      // console.log(transportExpertise)
      handleCreateTransporteurExpertise({ ...transportExpertise })
      //   console.clear()
    }

    const telephonefull = ref('')
    watch(() => (transportExpertise.telephonefull), () => {
      transportExpertise.telephone = ''
      transportExpertise.telephone = telephonefull.value.formattedNumber
    })

    return {
      transportExpertise,
      createTransportExpertise,
      required,
      offreProcess,
      typeExperts,
      typeExpertises,
      submittedNames,
      fields,
      documentProcess,
      documents,
      piece,
      handleImageSelected,
      clearPiece,
      queuePurchase,
      getImage,
      removeItem,
      avisMultiples,
      loader,
      imageUrl,
      items,
    }
  },

  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    formatter(value) {
      return value.toLowerCase()
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationIsConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountConditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPersonnalForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountPersonnalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationEntrepriseForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountEntrepriseRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.queuePurchase(this.piece)
          this.$refs['file-input'].reset()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
